import "./Surrounding.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconHospital } from "../../asset/svg/hospital.svg";
import { ReactComponent as IconEducation } from "../../asset/svg/education.svg";
import { ReactComponent as IconShopping } from "../../asset/svg/shopping.svg";
import surroundingImages from "../../asset/data/surroundingImages";

const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };
  const pointData = {
    poin1: [
      "Eka Hospital Cibubur",
      "Mitra Keluarga Hospital Cibubur",
      "Permata Hospital Cibubur",
      "Meilia Hospital Cibubur",
    ],
    poin2: [
      "Citra Berkat School",
      "Labschool Cibubur",
      "Global Mandiri School",
      "BPK Penabur School",
      "Bunda Hati Kudus School",
    ],
    poin3: [
      "15 Menit ke Pintu Tol Narogong",
      "1 Menit ke Pintu Tol Jatikarya Cibubur",
      "Living World Mall",
      "AEON Mall",
      "Mall Ciputra Cibubur",
    ],
  };
  return (
    <section id="surrounding" className="section-surrounding">
      <div className="container-surrounding">
        <h2 className="title">Surrounding Area</h2>
        <div className="container-image">
          <Slider {...settings}>
            {surroundingImages.map((item, index) => (
              <img
                src={item}
                alt="Area Sekitar Park Serpong"
                className="image"
              />
            ))}
          </Slider>
        </div>
        <div className="container-fasilitas">
          <div className="point">
            <div className="fasilitas">
              <IconHospital className="icon-fasilitas" />
              <span>Health Care</span>
            </div>
            {pointData.poin1.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconEducation className="icon-fasilitas" />
              <span>Education</span>
            </div>
            {pointData.poin2.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconShopping className="icon-fasilitas" />
              <span>Accessibility</span>
            </div>
            {pointData.poin3.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-loc"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Surrounding;
