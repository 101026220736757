import "./home.scss";
import Hero from "../../section/hero/Hero";
import About from "../../section/about/About";
import Promo from "../../section/promo/Promo";
import Produk from "../../section/produk/Produk";
import Surrounding from "../../section/surrounding/Surrounding";
import ScrollToHashElement from "../home/ScrollToHashElement";
const Home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Hero />
      <About />
      <Promo />
      <Produk />
      <Surrounding />
    </div>
  );
};

export default Home;
