import sr1 from "../surrounding/Surrounding.webp";
import sr2 from "../surrounding/Surrounding (2).webp";
import sr3 from "../surrounding/Surrounding (3).webp";
import sr4 from "../surrounding/Surrounding (4).webp";
import sr5 from "../surrounding/Surrounding (5).webp";
import sr6 from "../surrounding/Surrounding (6).webp";
import sr7 from "../surrounding/Surrounding (7).webp";
import sr8 from "../surrounding/Surrounding (8).webp";
import sr9 from "../surrounding/Surrounding (9).webp";

const surroundingImages = [sr1, sr2, sr3, sr4, sr5, sr6, sr7, sr8, sr9];
export default surroundingImages;
