import rt6 from "../produk/tipe 6/Tipe 6.webp";
import rt61 from "../produk/tipe 6/Tipe 6 (2).webp";
import rt62 from "../produk/tipe 6/Tipe 6 (3).webp";
import rt71 from "../produk/tipe 7/Tipe 7.webp";
import rt72 from "../produk/tipe 7/Tipe 7 (2).webp";
import rt81 from "../produk/tipe 8/Tipe 8.webp";
import rt82 from "../produk/tipe 8/Tipe 8  (2).webp";
import rt83 from "../produk/tipe 8/Tipe 8  (3).webp";
import rt84 from "../produk/tipe 8/Tipe 8  (4).webp";

const tipe6 = [rt6, rt61, rt62];
const tipe7 = [rt71, rt72];
const tipe8 = [rt81, rt82, rt83, rt84];

export { tipe6, tipe7, tipe8 };
