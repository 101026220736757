import "./CardProduk.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMaximize,
  faHouse,
  faBed,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const CardProduk = ({
  carousel,
  cardID,
  title,
  downPaymentType,
  price,
  details,
  whatsappLink,
}) => {
  const settings = {
    dots: carousel.length > 1,
    arrows: carousel.length > 1,
    infinite: carousel.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };
  return (
    <div className="card" id={cardID}>
      <Slider {...settings}>
        {carousel.map((image, index) => (
          <img
            src={image}
            alt={`Launching ${index + 1}`}
            className="card-img"
            key={index}
          />
        ))}
      </Slider>
      <div className="card-description">
        <h5 className="card-title">{title}</h5>
        <div className="card-sub">{downPaymentType}</div>
        <div className="card-price">
          <div className="ket">Start From</div>
          <div>
            <span>{price}</span>/bln
          </div>
        </div>
        <div className="gridspek">
          <div className="spek-item">
            <FontAwesomeIcon icon={faBed} className="icon" />
            <span>{details.bedRoom}</span>
          </div>
          <div className="spek-item">
            <FontAwesomeIcon icon={faShower} className="icon" />
            <span>{details.bathRoom}</span>
          </div>
          <div className="spek-item">
            <FontAwesomeIcon icon={faMaximize} className="icon" />
            <span>{details.areaSize}m²</span>
          </div>
          <div className="spek-item">
            <FontAwesomeIcon icon={faHouse} className="icon" />
            <span>{details.buildingSize}m²</span>
          </div>
        </div>
        <div className="container-btn">
          <button
            className="btn"
            onClick={() => window.open(whatsappLink, "_blank")}
          >
            <FontAwesomeIcon icon={faWhatsapp} className="icon" />
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardProduk;
