import "./Promo.scss";
import flyer from "../../asset/flyer.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Whatsapp } from "../../asset/svg/whatsapp.svg";
const Promo = () => {
  const pointData = {
    poin1: [
      "Soft DP",
      "Free Canopy",
      "Free Solar Panel",
      "Free CCTV",
      "Free Smartdoor Lock",
    ],
  };
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6282380709997&text=Halo+Mario.%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo%20Altea%20Boulevard%20Cibubur)%20%20http://alteablvd-cibubur.id/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  return (
    <section id="promo" className="section-promo">
      <div className="container">
        <div className="flyer">
          <img src={flyer} alt="promo flyer" />
        </div>
        <div className="highlights">
          <h4>PROMO KESELURUHAN ALTEA BOULEVARD CIBUBUR</h4>
          <div className="highlights-bottom">
            <div className="msg-hook">
              <div className="container-point">
                {pointData.poin1.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>

              <div className="container-btn">
                <div className="term">*T&C apply</div>
                <button onClick={waOpen} className="btn">
                  <Whatsapp className="icon" />
                  Hubungi Kami
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Promo;
