import "./Hero.scss";

const Hero = () => {
  return (
    <section id="hero" className="section-hero">
      <div className="container-hero">
        <div className="hero-description">
          <h3 className="hero-title">
            Temukan Kehidupan Harmoni di Altea Boulevard Cibubur
          </h3>
          <p className="hero-text">
            Altea Boulevard Cibubur, hunian modern di tengah keindahan alam.
            Dengan lokasi strategis dekat Tol Jatikarya dan LRT Harjamukti,
            nikmati kenyamanan hidup dengan fasilitas lengkap. Temukan rumah
            impian Anda di sini!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
