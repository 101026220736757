import "./About.scss";
import imgAbout from "../../asset/about.webp";

const About = () => {
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6282380709997&text=Halo+Mario.%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Altea%20Boulevard%20Cibubur)%20%20http://alteablvd-cibubur.id/&type=phone_number&app_absent=0",
      "_blank"
    );
  };

  return (
    <section id="about" className="section-about">
      <div className="container">
        <div className="container-title">
          <div className="label">About Us</div>
          <h3 className="title">THE JEWEL OF CIBUBUR </h3>
        </div>
        <div className="container-description">
          <p className="description">
            Altea Boulevard Cibubur adalah proyek perumahan modern seluas 75
            hektar di Cibubur, Jakarta, hasil kolaborasi Astra Land dan Sinar
            Mas Land. Dirancang sebagai township yang memadukan keindahan alam
            dengan fasilitas lengkap, Altea BLVD menawarkan hunian nyaman dengan
            akses strategis, hanya 1 menit dari Tol Jatikarya dan dekat stasiun
            LRT Harjamukti.
          </p>
          <button onClick={waOpen} className="btn-about">
            Learn More
          </button>
        </div>
        <div className="container-img">
          <img src={imgAbout} alt="AlteaBLVD" />
        </div>
      </div>
    </section>
  );
};

export default About;
